import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";

const Pro = () => {
  return (
    <Layout>
      <Seo title="Roadmap" />
      <h1>
        <strong>Roadmap</strong>
      </h1>
      <h2>
        This is what we've currently done and what we're going to develop for
        you! Stay tuned.
      </h2>
      <s style={{ color: "#6a676b" }}>Create an account</s>
      <br />
      <s style={{ color: "#6a676b" }}>YouTube</s>
      <br />
      <s style={{ color: "#6a676b" }}>Instagram</s>
      <br />
      <s style={{ color: "#6a676b" }}>Twitter</s>
      <br />
      <s style={{ color: "#6a676b" }}>Weekly mail</s>
      <br />
      <s style={{ color: "#6a676b" }}>Show follower count</s>
      <s style={{ color: "#6a676b" }}>in "my accounts"</s>
      <br />
      <s style={{ color: "#6a676b" }}>facebook</s>
      <br />
      <s style={{ color: "#6a676b" }}>Show in ui if account</s>
      <s style={{ color: "#6a676b" }}>name is correct or not</s>
      <br />
      <s style={{ color: "#6a676b" }}>Add changes to</s>
      <s style={{ color: "#6a676b" }}>the previous week to mail</s>
      <br />
      <s style={{ color: "#6a676b" }}>Change database account</s>
      <s style={{ color: "#6a676b" }}>connecten to social</s>
      <s style={{ color: "#6a676b" }}>media users</s>
      <br />
      Multiple social media accounts
      <br />
      <s style={{ color: "#6a676b" }}>Show count of followers / </s>
      <s style={{ color: "#6a676b" }}>likes on the landingpage</s>
      <br />
      <s style={{ color: "#6a676b" }}>Sending instant test mail</s>
      <br />
      <s style={{ color: "#6a676b" }}>Login with code for app usage</s>
      <br />
      Individual times for the e-mail
      <br />
      <>Pinterest</>
      <br />
      <>GitHub</>
      <br />
      <>GitLab</>
      <br />
      <>LinkedIn</>
      <br />
      <s style={{ color: "#6a676b" }}>Get live facebook insights</s>
      <br />
      <>Get live instagram insights</>
      <br />
      <>Get live twitter insights</>
      <br />
      <s style={{ color: "#6a676b" }}>Multi lang support</s>
    </Layout>
  );
};

export default Pro;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
